import React from "react";
import Layout from "./src/components/layout-comps/Layout";
import { ConfigProvider } from "antd";
import plPL from "antd/locale/pl_PL";
import { UserProvider } from "./src/context/UserContext";
import 'leaflet/dist/leaflet.css';

export function wrapRootElement({ element, props }) {
  return (
    <ConfigProvider locale={plPL}>
      <UserProvider>
        <Layout {...props}>{element}</Layout>
      </UserProvider>
    </ConfigProvider>
  );
}

export const onRenderBody = ({ setHeadComponents }) => {
    setHeadComponents([
      <link
        rel="icon"
        href="https://hookrod.s3.eu-central-1.amazonaws.com/Logo/sygnet%403x.png"
        type="image/png"
        key="favicon"
      />,
    ]);
  };
