import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Footer = ({ noFixedPosition }) => (
  <FooterCss className='footer_css' noFixedPosition={noFixedPosition}>
    <div className='container'>
      <div className='left'>
        <ul className="partnerzy-list">
          <li>
            <Link to='/partnerzy'>Partnerzy</Link>
          </li>
        </ul>
      </div>
      <div className='center'>
        <ul className="join-us-list">
          <li>
            <Link to='/business' className='bold'>
              Dołącz do nas
            </Link>
          </li>
        </ul>
      </div>
      <div className='right'>
        <div className="documents">
          <span className="documents-title">Dokumenty:</span>
          <ul className="documents-list">
            <li>
              <Link to='/service-terms' className='bold document-link'>
                Regulamin serwisu
              </Link>
            </li>
            <li>
              <Link to='/privacy-policy' className='bold document-link'>
                Polityka prywatności
              </Link>
            </li>
          </ul>
        </div>
        <div className="payments">
          <Link to='/payments' className='bold'>
            Płatności
          </Link>
        </div>
      </div>
    </div>
  </FooterCss>
);

const FooterCss = styled.footer`
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(22, 56, 50, 0.9);
  height: auto;
  justify-content: center;
  font-family: Lato;
  z-index: 111;
  align-items: center;
  overflow: hidden;
  padding: 10px 0; // Zmniejszony padding

  @media (max-width: 589px) {
    position: relative;
    height: auto;
    flex-direction: column;
    text-align: center;
    padding: 10px 0;
  }

  .container {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between; // Rozłożenie elementów na boki
    align-items: center;
    gap: 10px; // Zmniejszony odstęp między sekcjami

    @media (max-width: 589px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  .left {
    flex: 1; // Partnerzy na skrajnej lewej
    display: flex;
    justify-content: flex-start; // Wyrównanie do lewej
    align-items: center;
  }

  .center {
    flex: 1; // Dołącz do nas na środku
    display: flex;
    justify-content: center; // Wyśrodkowanie
    align-items: center;
  }

  .right {
    flex: 1; // Dokumenty i Płatności na skrajnej prawej
    display: flex;
    justify-content: flex-end; // Wyrównanie do prawej
    align-items: center;
    gap: 20px; // Odstęp między dokumentami a płatnościami
  }

  .partnerzy-list,
  .join-us-list,
  .documents-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px; // Zmniejszony odstęp między elementami listy

    @media (max-width: 589px) {
      flex-direction: column;
      gap: 5px;
    }
  }

  .documents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .documents-title {
    font-size: 14px;
    color: var(--white);
    font-weight: 700;
    margin-bottom: 5px !important;

     @media (max-width: 589px) {
      font-size: 12px;
    }
  }

  .documents-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .document-link {
    display: inline-block;
    width: 160px; // Stała szerokość dla obu linków
    text-align: left; // Wyrównanie tekstu do lewej
  }

  .payments {
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: var(--white);
    font-size: 14px;
    transition: color 0.3s ease;

    &:hover {
      color: #ccc;
    }

    @media (max-width: 589px) {
      font-size: 12px;
    }
  }

  .bold {
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    .container {
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
    .column {
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    .container {
      grid-template-columns: repeat(3, 1fr);
      text-align: center;
    }
    .column {
      align-items: center;
    }
  }
`;

export default Footer;